<template>
  <v-row no-gutters class="survey-container">
    <v-col class="survey-header">
      <h1 class="survey-title">
        Uh Oh
      </h1>
    </v-col>

    <div style="display: flex; flex-direction: column; min-height: 100vh;">
      <v-col class="survey-body text-center" style="flex-grow: 1;">
        <div class="survey-width-limit">
          <img src="@/assets/sorry_icon.svg" class="py-5" />
          <h4 class="text-h4 font-weight-bold mb-5">
            Sorry, but we're having trouble locating this survey!
          </h4>
          <p>
            For assistance, please reach out to us at
            <a href="mailto:support@swiftbunny.com" class="primary--text"
              >support@swiftbunny.com</a
            >, and we'll do what we can to help.
          </p>
        </div>
      </v-col>
      <survey-footer style="flex-shrink: 1; flex-grow: 0;" />
    </div>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      service: state => state.surveyRenderService
    })
  }
};
</script>

<style lang="scss" scoped>
.survey-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  margin-top: 0;
  margin-bottom: 0;
  background-color: #f1f1f1;
  display: block;

  .survey-width-limit {
    max-width: 1200px;
    margin: 0 auto;
  }

  .survey-header {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: #ffffff;
    border-bottom: 1px solid #aaaaaa;
  }

  .survey-title {
    text-align: center;
    font-weight: 600;
    font-size: 1.5rem;
    padding-top: 1rem;
    color: #007dba;
  }

  .survey-body {
    background-color: #f1f1f1;
    padding: 1rem;
    padding-top: 110px;

    &.is-preview {
      padding-top: 156px;
    }

    * {
      color: #444444;
      fill: #444444;
    }
  }
}
</style>
