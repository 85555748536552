<template>
  <v-col cols="12">
    <div class="survey-question__input">
      <div class="input">
        <div class="multi-select">
          <v-row>
            <v-col
              v-for="option in question.options"
              :key="option.id"
              md="6"
              cols="12"
              class="multi-select__option"
            >
              <input
                :id="`question-${question.id}-option-${option.id}-checkbox`"
                v-model="selected"
                type="radio"
                :value="option.id"
              />
              <label :for="`question-${question.id}-option-${option.id}-checkbox`">
                <v-row align="center" justify="start" dense class="no-pointer-events">
                  <v-col cols="auto">
                    <cp-icon class="unchecked" icon="binary-default" />
                    <cp-icon class="checked" icon="binary-selected" />
                  </v-col>
                  <v-col class="multi-select__option__description no-select">
                    {{ option.body }}
                  </v-col>
                </v-row>
              </label>
            </v-col>
            <v-col v-if="question.show_other" md="6" cols="12" class="multi-select__option">
              <input
                :id="`question-${question.id}-option-other-checkbox`"
                v-model="selected"
                true-value="other"
                type="checkbox"
              />
              <label :for="`question-${question.id}-option-other-checkbox`">
                <v-row align="center" justify="start" dense>
                  <v-col cols="auto">
                    <cp-icon class="unchecked" icon="binary-default" />
                    <cp-icon class="checked" icon="binary-selected" />
                  </v-col>
                  <v-col class="multi-select__option__description no-select">
                    {{ $store.state.translations.terms.other }}:
                  </v-col>
                </v-row>
              </label>
              <div class="input input--text-box">
                <textarea
                  v-if="selectedOther"
                  v-model="otherText"
                  :placeholder="$store.state.translations.terms.comment_here"
                  class="input__field"
                ></textarea>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </v-col>
</template>

<script>
import InteractsWithResponse from '@mixins/InteractsWithResponse';

const responseKey = 'multiple_choice';

export default {
  mixins: [InteractsWithResponse],

  data() {
    return {
      responseKey,
      selected: this.initialSelected(),
      otherText: this.responseValue(responseKey, {})['other'] || null,
    };
  },

  computed: {
    selectedOther() {
      return this.selected === 'other';
    },
  },

  watch: {
    selected() {
      if (this.selectedOther) {
        this.updateResponse();
      } else {
        this.addResponse({selected: [this.selected]});
      }
    },

    otherText() {
      this.updateResponse();
    },
  },

  methods: {
    initialSelected() {
      const selected = this.responseValue(responseKey, {selected: []})['selected'];

      return selected.length ? selected[0] : null;
    },

    updateResponse() {
      this.addResponse({selected: ['other'], other: this.otherText});
    },
  },
};
</script>

<style lang="scss">
.multiple-select-other {
  .label-value {
    display: flex;
    align-items: center;
  }
  .v-text-field {
    padding: 0;
    margin: 0;
  }
}
</style>
