import Vue from "vue";
import VueGtm from "@gtm-support/vue2-gtm";
import VueRouter from "vue-router";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes
});

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID,
  queryParams: {
    gtm_auth: process.env.VUE_APP_GTM_AUTH,
    gtm_preview: process.env.VUE_APP_GTM_PREVIEW,
    gtm_cookies_win: process.env.VUE_APP_GTM_COOKIES_WIN
  },
  defer: false,
  compatibility: false,
  enabled: process.env.VUE_APP_GTM_ENABLED,
  debug: process.env.VUE_APP_MODE != "production",
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false
});

export default router;
