<template>
  <transition v-if="initial_page_load && loading" name="fade">
    <img
      src="@/assets/survey/blue_bunny_loader.gif"
      alt="Bunny loader"
      class="bunny-loader"
    />
  </transition>
  <transition v-else :name="initial_page_load ? 'fade' : ''">
    <component :is="page" />
  </transition>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "Survey",

  props: {
    slug: { required: false, default: "" }
  },

  data() {
    return {
      loading: true,
      errorPage: false
    };
  },

  computed: {
    ...mapState({
      status: state => state.ingageSurvey.survey.status,
      survey_status: state => state.ingageSurvey.survey.survey_status,
      name: state => state.ingageSurvey.survey.name,
      terms: state => state.translations.terms,
      has_user: state => state.ingageSurvey.survey.has_user,
      language_preference: state => state.translations.language_preference,
      language_initialized: state => state.translations.language_initialized,
      initial_page_load: state => state.initial_page_load,
      current_user: state => state.auth.currentUser
    }),

    initial_page_load: {
      set() {
        this.setInitialPageLoad(false);
      },
      get() {
        return this.$store.state.initial_page_load;
      }
    },

    page() {
      if (this.errorPage) return "ErrorPage";

      let section = this.$route.query.section;

      if (this.survey_status && this.survey_status != "active") {
        return "SurveyClosed";
      }

      switch (this.status) {
        case "missing":
          return "SurveyMissing";
        case "pending":
          if (section) {
            return "SurveyContents";
          }
          return "SurveyInfo";
        case "submitted":
          return "SurveyComplete";
        case "closed":
          return "SurveyClosed";
        default:
          return null;
      }
    }
  },

  watch: {
    name() {
      if (this.name) {
        document.title = `${this.name} | Swift Bunny`;
        return;
      }

      document.title = "Survey | Swift Bunny";
    }
  },

  created() {
    let self = this;
    this.$CpEvent.$on("user-loaded", () => {
      if (self.$route.name == "survey.profile") {
        self.$store.state.ingageSurvey.serviceSlug = "ingage";
        self.$store
          .dispatch("fetchProfileSurvey", {
            locale: self.language_preference
          })
          .then(() => {
            self.initSurvey(
              self.$store.state.ingageSurvey.slug,
              self.language_preference
            );
          });
      } else {
        self.initSurvey(self.slug, self.language_preference);
      }
    });
  },

  methods: {
    ...mapMutations([
      "setInitialPageLoad",
      "setLanguagePreference",
      "setLanguageInitialized"
    ]),

    initSurvey(slug, locale) {
      this.$store.dispatch("fetchAvailableLocales");
      this.$store.dispatch("fetchTerms", locale);

      this.$store
        .dispatch("fetchSurvey", {
          slug: slug,
          locale: locale
        })
        .catch(({ response }) => {
          if (response.status >= 400 && response.status <= 599) {
            this.errorPage = true;
          }
        })
        .then(() => {
          let self = this;
          self.loading = false;

          setTimeout(function() {
            self.initial_page_load = false;
          }, 700);
        });
    }
  }
};
</script>

<style scoped lang="scss">
.bunny-loader {
  position: absolute;
  width: 70%;
  max-width: 120px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  overflow: hidden;
  padding: 1rem;
  background-color: $white;
  box-shadow: 0 2px 15px 0 rgba($black, 0.05);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
