import {
  responseKeysForQuestion,
  questionKeyPair,
  compileLocalResponse
} from "@/lib/Survey";
import { Api } from "bunnyApi";

const bApi = new Api(`${process.env.VUE_APP_ENGAGE_API_PATH}`);

class Included {
  constructor(items) {
    this.items = items;
  }

  findByObject(base) {
    return this.find(item => item.id == base.id && item.type == base.type);
  }

  find(callback) {
    return this.items.find(callback);
  }

  getQuestions(questions) {
    return questions.data.map(question => {
      const includedQuestion = this.findByObject(question);
      const options = includedQuestion.relationships.options.data.map(
        option => {
          return { id: option.id, ...this.findByObject(option).attributes };
        }
      );

      return {
        id: question.id,
        ...includedQuestion.attributes,
        options,
        children: this.getQuestions(
          includedQuestion.relationships.child_questions
        )
      };
    });
  }

  getResponses(questions) {
    let responses = {};
    questions.forEach(question => {
      const includedResponse = this.find(
        item =>
          item.type == "response" && item.attributes.question_id == question.id
      );
      const response = includedResponse ? includedResponse.attributes : null;
      if (response) {
        responseKeysForQuestion(question).forEach(key => {
          if (response.hasOwnProperty(key) && response[key] !== null) {
            const localResponse = compileLocalResponse(
              question,
              key,
              response[key]
            );
            responses[localResponse.key] = localResponse.value;
          }
        });
      }
      if (question.children.length) {
        responses = { ...responses, ...this.getResponses(question.children) };
      }
    });

    return responses;
  }
}

export default {
  state: {
    slug: null,
    serviceSlug: null,
    survey: {}
  },

  getters: {
    parentHasResponse: (state, getters) => (questionId, criteria) => {
      return (
        criteria.filter(criterion => {
          return (
            getters.responseValueForKey(questionId, criterion.key) !== false
          );
        }).length === criteria.length
      );
    },

    passesCriteria: (state, getters) => (questionId, criteria) => {
      if (!criteria) {
        return false;
      }

      const passedCriteria = criteria.filter(criterion => {
        const value = getters.responseValueForKey(questionId, criterion.key);
        if (value === false) {
          return false;
        }

        if (["multiple_choice", "multiple_select"].includes(criterion.key)) {
          return value.selected.filter(
            selection =>
              criterion.values.includes(selection) ||
              criterion.values.includes(parseInt(selection))
          ).length;
        }

        return (
          criterion.values.includes(value) ||
          criterion.values.includes(parseInt(value))
        );
      });

      return passedCriteria.length === criteria.length;
    },

    response: (state, getters) => question => {
      let response = {};

      responseKeysForQuestion(question).forEach(key => {
        const r = getters.responseForKey(question.id, key);

        if (r) {
          response = { ...response, ...r };
        }
      });

      return response;
    },

    responseValueForKey: (state, getters) => (questionId, key) => {
      const response = getters.responseForKey(questionId, key);
      if (!response) {
        return false;
      }

      const value = response[key];
      if (value === undefined || value === null) {
        return false;
      }

      return value;
    },

    responseForKey: state => (questionId, key) => {
      return state.survey.responses[questionKeyPair(questionId, key)];
    },

    buildUrl(state, getters, rootState) {
      return (...args) =>
        [
          rootState.translations.language_preference || "en",
          "v1",
          ...args
        ].join("/");
    },

    buildUrlWithServiceSlug(state, getters, rootState) {
      return (...args) =>
        [
          rootState.translations.language_preference || "en",
          "v1",
          state.serviceSlug,
          ...args
        ].join("/");
    }
  },

  mutations: {
    setSlug(state, { slug }) {
      if (slug) {
        localStorage.slug = slug;
      }

      state.slug = localStorage.slug;
    },

    setSurvey(state, { survey }) {
      state.survey = survey.attributes;
      state.survey.has_user = survey.relationships.recipient.data != null;
    },

    updateSurveyStatus(state, { status }) {
      state.survey.status = status;
    },

    setSurveyRelationships(state, { survey, included }) {
      const items = new Included(included);
      const includedSurvey = items.findByObject(survey);
      const hydratedSurvey = {
        ...state.survey,
        ...includedSurvey.attributes,
        sections: []
      };

      hydratedSurvey.sections = includedSurvey.relationships.sections.data.map(
        section => {
          const includedSection = items.findByObject(section);
          const questions = items.getQuestions(
            includedSection.relationships.questions
          );

          return {
            ...includedSection.attributes,
            questions,
            question_count: questions.length
          };
        }
      );

      hydratedSurvey.responses = {};
      hydratedSurvey.sections.forEach(section => {
        hydratedSurvey.responses = {
          ...hydratedSurvey.responses,
          ...items.getResponses(section.questions)
        };
      });

      state.survey = hydratedSurvey;
    },

    setResponses(state, responses) {
      state.survey.responses = responses;
    }
  },

  actions: {
    fetchSurvey({ commit, state, getters }, { slug }) {
      if (state.slug == null) commit("setSlug", { slug });

      const url = getters.buildUrlWithServiceSlug("surveys_users", slug);
      return bApi.get(url).then(({ data, included }) => {
        commit("setSurvey", { survey: data });
        commit("setSurveyRelationships", {
          survey: data.relationships.survey.data,
          included
        });

        if (state.survey.available_locales != null) {
          commit("setAvailableLocales", state.survey.available_locales);
        }
      });
      // .catch(({ response }) => {
      //   if (response && response.status === 404) {
      //     commit("updateSurveyStatus", { status: "missing" });
      //   }
      // });
    },

    fetchProfileSurvey({ commit, state }, { locale = "en" }) {
      return bApi
        .authorize()
        .get(`en/v1/company-profile-assessment`)
        .then(slug => {
          commit("setSlug", slug);
        });
    },

    fetchLanguagePreference({ rootState, getters }, survey_slug) {
      const url = getters.buildUrlWithServiceSlug("users/language-preference");
      return bApi
        .get(url, {
          survey_slug: survey_slug
        })
        .then(data => {
          rootState.translations.language_preference = data.locale || "en";
        });
    },

    submitSurvey({ commit, state, getters }) {
      const url = getters.buildUrlWithServiceSlug("surveys_users", state.slug);
      bApi.put(url, {
        surveys_user: { status: "submitted" }
      });
      commit("updateSurveyStatus", { status: "submitted" });
    },

    postResponse({ state, rootState, getters }, { question, key, value }) {
      const responseData = {
        question_id: question.id,
        surveys_user_slug: state.slug
      };
      responseData[key] = value;

      let skipAuthorization = !rootState.auth.currentUser.id;
      const url = getters.buildUrlWithServiceSlug("responses");

      bApi.authorize(skipAuthorization).post(url, {
        response: responseData
      });
    },

    fillResponse({ commit, state }, { question, key, value }) {
      const newResponses = {};
      const localResponse = compileLocalResponse(question, key, value);
      newResponses[localResponse.key] = localResponse.value;

      commit(
        "setResponses",
        Object.assign({}, state.survey.responses, newResponses)
      );
    },

    clearResponse({ commit, state }, { question, key }) {
      const keyPair = questionKeyPair(question.id, key);
      const response = state.survey.responses[keyPair];
      if (!response || response[key] == null) {
        const newResponses = Object.assign({}, state.survey.responses);
        delete newResponses[keyPair];

        commit("setResponses", Object.assign({}, newResponses));
      }
    }
  }
};
