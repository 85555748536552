<template>
  <v-container>
    <div v-if="survey.name" class="survey">
      <ProgressHeader
        @complete="pending = false"
        @openModal="modalOpen = true"
      />

      <div class="survey-questions">
        <Section
          v-for="(section, index) in sections"
          v-show="currentIndex == index"
          :key="section.slug"
          :section="section"
        />

        <v-row
          class="survey-navigation pt-7"
          align="center"
          justify="center"
          no-gutters
        >
          <v-btn
            large
            v-if="canGoBack"
            class="text-subtitle-1 font-weight-bold mr-2"
            color="primary"
            @click="back"
          >
            <cp-icon
              icon="chevron-double-left"
              small
              class="mr-2"
              color="white"
            />
            {{ $store.state.translations.terms.back }}
          </v-btn>

          <v-btn
            large
            v-if="!onLastSection"
            color="primary"
            :disabled="!canGoNext"
            @click="next"
            class="text-subtitle-1 font-weight-bold ml-2"
          >
            {{ $store.state.translations.terms.next }}
            <cp-icon
              icon="chevron-double-right"
              small
              class="ml-2"
              color="white"
            />
          </v-btn>

          <cp-dialog v-if="canSubmit" width="500" header-background>
            <template #activator>
              <v-btn
                large
                color="primary"
                class="text-subtitle-1 font-weight-bold ml-2"
              >
                {{ $store.state.translations.terms.submit }}
                <v-icon class="ml-2" color="white">mdi-check-circle</v-icon>
              </v-btn>
            </template>
            <template #title>
              {{ $store.state.translations.terms.submit }}
              {{ $store.state.translations.terms.survey }}
            </template>
            <template #body>{{
              $store.state.translations.terms.submit_confirmation
            }}</template>
            <template #actions="{close}">
              <v-col cols="auto" class="ml-auto">
                <button
                  class="survey-button survey-button--text"
                  @click="close"
                >
                  {{ $store.state.translations.terms.cancel }}
                </button>
              </v-col>
              <v-col cols="auto">
                <button
                  class="survey-button button-color--primary"
                  @click="submit"
                >
                  {{ $store.state.translations.terms.submit }}
                </button>
              </v-col>
            </template>
          </cp-dialog>
        </v-row>

        <div class="survey-footer">
          <cp-icon class="logo" icon="swift-bunny-logo-h" />
          <p>
            © 2020 Swift Bunny LLC
            {{ $store.state.translations.terms.rights_reserved }}
          </p>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { responseKeysForQuestion, questionKeyPair } from "@/lib/Survey";

export default {
  name: "SurveyContents",

  data() {
    return {
      modalOpen: false,
      pending: true,
      current: parseInt(this.$route.query.section) || 1
    };
  },

  computed: {
    ...mapState({
      survey: state => state.ingageSurvey.survey,
      terms: state => state.translations.terms
    }),
    ...mapGetters(["copyright"]),

    sections() {
      return this.survey.sections.filter(section => {
        return this.sectionVisible(section);
      });
    },

    onLastSection() {
      return this.sections.length == this.current;
    },

    canGoNext() {
      return (
        this.sections.length > this.current ||
        (this.onLastSection && Object.values(this.survey.responses).length)
      );
    },

    canGoBack() {
      return this.current > 1;
    },

    canSubmit() {
      return this.onLastSection && this.$route.name != "survey.profile";
    },

    currentIndex() {
      return this.current - 1;
    },

    currentSection() {
      return this.sections[this.currentIndex];
    }
  },

  watch: {
    "$route.query.section": function(section) {
      this.current = section;
    }
  },

  methods: {
    submit() {
      if (this.canSubmit) {
        this.$store.dispatch("submitSurvey");
      }
    },

    next() {
      this.goToSection(Math.min(this.current + 1, this.sections.length));
    },

    back() {
      this.goToSection(Math.max(this.current - 1, 1));
    },

    goToSection(section) {
      const { name, params } = this.$route;

      this.$router.push({ name, params, query: { section } });
      this.scrollToTop();
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    sectionVisible(section) {
      const { question_id: questionId, question_criteria: criteria } = section;

      if (!questionId) {
        return true;
      }

      const passesCriteria = this.passesCriteria(questionId, criteria);

      if (passesCriteria) {
        section.questions.forEach(question => {
          responseKeysForQuestion(question).forEach(key => {
            this.$store.dispatch("clearResponse", { question, key });
          });
        });
      } else if (this.parentHasResponse(questionId, criteria)) {
        section.questions.forEach(question => {
          responseKeysForQuestion(question).forEach(key => {
            if (
              this.survey.responses[questionKeyPair(question.id, key)] ==
              undefined
            ) {
              this.$store.dispatch("fillResponse", {
                question,
                key,
                value: null
              });
            }
          });
        });
      }

      return passesCriteria;
    },

    passesCriteria(questionId, criteria) {
      return this.$store.getters.passesCriteria(questionId, criteria);
    },

    parentHasResponse(questionId, criteria) {
      return this.$store.getters.parentHasResponse(questionId, criteria);
    }
  }
};
</script>
