<template>
  <div class="progress-header">
    <div class="progress-header__margin">
      <v-row no-gutters align="center">
        <v-col class="mr-auto" cols="auto">
          <h4 class="progress-header__title">{{ title }}</h4>
        </v-col>
        <v-col cols="auto">
          <language-picker v-if="title != 'Company Profile Assessment'" />
        </v-col>
        <v-col cols="auto">
          <cp-dialog width="500" header-background>
            <template #activator>
              <cp-btn
                class="progress-header__instructions"
                fab
                dense
                flushBody
                outlined
                title="Instructions"
              >
                <cp-icon small icon="info" />
              </cp-btn>
            </template>
            <template #title>
              {{ $store.state.translations.terms.survey_instructions }}
            </template>
            <template v-slot:body>
              <div v-if="isProfileAssessment">
                <p>
                  The Company Profile Assessment is an inventory of the current
                  business policies, procedures, and practices which are in
                  place to support your employees. The information you input
                  here will be used in conjunction with employee feedback
                  collected by the Ingage by Swift Bunny™ system to inform and
                  document your organization’s successes, opportunities, and
                  recommendations.
                </p>
                <p>
                  The progress meter at the top of the page indicates your
                  progress in completing the Company Profile Assessment. Your
                  responses will be saved automatically upon entry. Any updates
                  or changes will also be saved and archived in order to monitor
                  improvements over time.
                </p>
              </div>
              <div v-else-if="instructions" v-html="instructions"></div>
              <p
                v-for="(paragraph, index) in $store.state.translations.terms
                  .survey_information"
                v-else
                :key="index"
                v-html="paragraph"
              ></p>
            </template>
          </cp-dialog>
        </v-col>
      </v-row>
    </div>

    <div class="progress-header__bar" :style="`width: ${progressPercentage}%;`">
      <div class="progress-header__bar__value">
        {{ progressPercentage }}%
        <cp-icon x-small color="white" icon="swift-bunny-icon" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState({
      title: state => state.ingageSurvey.survey.name,
      responses: state => state.ingageSurvey.survey.responses,
      instructions: state => state.ingageSurvey.survey.instructions
    }),

    questionCount() {
      return this.$store.state.ingageSurvey.survey.sections.reduce(
        (count, section) => {
          // [].reduce crashes if the array is empty. odd.
          if (!section.questions.length) return count;

          const questionCount = section.questions
            .map(question => {
              return ["Agreement/Importance", "agreement-importance"].includes(
                question.kind
              )
                ? 2
                : 1;
            })
            .reduce((a, b) => a + b);

          return count + questionCount;
        },
        0
      );
    },

    progress() {
      return Object.values(this.responses).filter(
        response => response.type == "question"
      ).length;
    },

    progressPercentage() {
      return Math.floor((this.progress / this.questionCount) * 100);
    },

    remaining() {
      return Math.abs(this.questionCount - this.progress);
    },

    isProfileAssessment() {
      return this.title == "Company Profile Assessment";
    }
  },

  watch: {
    remaining(remaining) {
      if (!remaining) {
        this.$emit("complete");
      }
    }
  }
};
</script>
