import Vue from "vue";
import Vuex from "vuex";

import survey from "./modules/survey";
import question from "./modules/question";
import campaign from "./modules/campaign";
import ingageSurvey from "./modules/ingageSurvey";
import translations from "./modules/translations";
import surveyRenderService from "./modules/surveyRenderService";
import auth from "bunny-frontend-api/src/store/auth.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    survey,
    question,
    campaign,
    ingageSurvey,
    translations,
    surveyRenderService,
    auth
  },

  state: {
    initial_page_load: true
  },

  getters: {
    copyright() {
      const year = new Date().getFullYear();

      return `<a href="mailto:support@swiftbunny.com" class="white--text">support@swiftbunny.com</a> | <a href="#" class="white--text">${translations.terms.privacy_policy}</a></br>© ${year} Swift Bunny LLC All Rights Reserved.`;
    }
  },

  actions: {
    handleErrorResponse({ state }, error) {
      switch (error.data.error_id) {
        case "impersonation_prohibited":
          this._vm.$CpEvent.$emit("snackAlert", {
            message: error.data.message,
            color: "error"
          });
          throw error.data.error_id;
          break;

        case "campaign_closed":
          this._vm.$CpEvent.$emit("snackAlert", {
            message: error.data.message,
            color: "error"
          });
          throw error.data.error_id;
          break;

        default:
          this._vm.$CpEvent.$emit("snackAlert", {
            message:
              error.data.message || "There was an error with this request",
            color: "error"
          });
          throw "default_error";
      }
    }
  },

  mutations: {
    setInitialPageLoad(state, value) {
      this.state.initial_page_load = value;
    }
  }
});
