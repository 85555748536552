import store from "@/store";

export default function canViewCPA(to, from, next) {
  store.dispatch("auth/fetchCurrentUser").then(() => {
    const isSwiftBunny = store.getters["auth/clientIsAdmin"];
    const hasRoles = store.getters["auth/userIs"]([
      "engage-program-manager",
      "intouch-admin",
      "client-admin",
    ]);

    if (isSwiftBunny || hasRoles) return next();

    return next("/");
  });
}
