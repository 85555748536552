import debounce from 'lodash.debounce';

export default {
    props: {
        question: {type: Object, required: true},
        response: {type: Object, required: true},
    },

    created() {
        this.postResponse = debounce(function(data) {
            this.$store.dispatch('postResponse', data);
        }, 1000);
    },

    methods: {
        responseValue(responseKey, defaultValue = null) {
            if (
                !this.response ||
                (['multiple_choice', 'multiple_select'].includes(responseKey) && !this.response[responseKey])
            ) {
                return defaultValue;
            }

            return this.response[responseKey];
        },

        addResponse(value) {
            const data = {question: this.question, key: this.responseKey, value};
            this.$store.dispatch('fillResponse', data);
            this.postResponse(data);
        },
    },
};
