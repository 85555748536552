<template>
  <survey-bumper-content :survey="survey" page-context="landing_page" />
</template>

<script>
import {mapState} from 'vuex';

export default {
  computed: {
    ...mapState({
      language_preference: (state) => state.translations.language_preference,
      survey: (state) => state.ingageSurvey.survey,
    }),
  },
};
</script>
