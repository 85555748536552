export default {
  setSurvey(state, survey) {
    state.instance = Object.assign(state.instance, survey);
  },

  setSurveys(state, surveys) {
    state.collection = surveys;
  },

  setCampaignSummary(state, surveys) {
    state.campaign_summary_collection = surveys;
  },

  setSurveySummaries(state, surveys) {
    state.summary_collection = surveys;
  },

  setRecentlyEndedSurveySummaries(state, surveys) {
    state.recently_ended_summary_collection = surveys;
  },

  setQuestionsOnSection(state, questions) {
    state.section_instance.questions = questions;
  },

  setSurveyQuestionIds(state, question_ids) {
    state.instance.question_ids = question_ids;
  },

  resetSurveyInstance(state) {
    state.instance = {
      id: null,
      name: "",
      description: "",
      status_slug: "",
      sections: [],
      question_ids: [],
      all_assigned_count: {
        email: 0,
        weblink: 0
      },
      all_respondent_count: {
        email: 0,
        weblink: 0
      },
      all_response_rate: 0
    };
    state.new_survey_created = false;
  },

  resetSurveyCollection(state) {
    state.collection = [];
    state.filters = {
      createdBy: null,
      status: [
        { text: "Draft", value: "draft" },
        { text: "Active", value: "active" }
      ]
    };
    state.new_survey_created = false;
  },

  resetSectionInstance(state) {
    state.section_instance = {
      id: null,
      name: "",
      instructions: "",
      questions: [],
      position: null
    };
  },

  setSurveyArchived(state, data) {
    state.instance.status_slug = "archived";
  },

  setSection(state, section) {
    state.section_instance = { ...section, questions: [] };
  },

  setSections(state, sections) {
    state.instance.sections = sections.map(x => ({
      ...x.attributes,
      id: x.id,
      questions: []
    }));
  }
};
