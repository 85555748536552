<template>
  <main>
    <v-app class="cp-hide-background-img">
      <cp-banner v-if="auth.is_impersonating" color="#009cde">
        <template #body>
          <h4>Operating as {{ auth.impersonating_client_name }}</h4>
        </template>
        <template #actions>
          <v-btn outlined color="#ffffff" :href="resetImpersonateUrl">
            Stop
          </v-btn>
        </template>
      </cp-banner>
      <v-container class="app-container pa-0" fluid>
        <cp-icons />
        <router-view />
      </v-container>
      <v-snackbar
        v-model="snackbar.show"
        :color="snackbar.color"
        top
        timeout="4000"
      >
        <div class="text-center">{{ snackbar.message }}</div>
      </v-snackbar>
    </v-app>
  </main>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      logoutPath: process.env.VUE_APP_LOGOUT_PATH,
      snackbar: {
        show: false,
        color: "",
        message: ""
      }
    };
  },

  computed: {
    ...mapState({
      auth: state => state.auth,
      surveys: state => state.ingageSurvey,
      translations: state => state.translations
    }),

    resetImpersonateUrl() {
      return `${process.env.VUE_APP_AUTH_API_PATH}/admin/impersonates/reset/`;
    }
  },

  mounted() {
    this.$CpEvent.$on("snackAlert", snackData => {
      this.snackbar = {
        show: true,
        color: snackData.color || "success",
        ...snackData
      };
    });
  },

  created() {
    this.surveys.serviceSlug = this.$route.params.serviceSlug;

    if (this.$route.name == "feedback_weblink") {
      return;
    } else if (
      ["survey", "feedback"].includes(this.$route.name) &&
      this.$route.params.slug
    ) {
      this.$store
        .dispatch("fetchLanguagePreference", this.$route.params.slug)
        .then(() => {
          if (this.$route.query.locale) {
            this.translations.language_preference = this.$route.query.locale;
          }

          this.$CpEvent.$emit("user-loaded");
        });
    } else if (this.$route.query.temp_token) {
      const tempTokenData = VueJwtDecode.decode(this.$route.query.temp_token);
      this.$store.commit("auth/setTemporalUser", tempTokenData);
    } else {
      this.$store.dispatch("auth/fetchCurrentUser").then(() => {
        if (this.$route.query.locale) {
          this.translations.language_preference = this.$route.query.locale;
        } else if (this.auth.currentUser.locale) {
          this.translations.language_preference = this.auth.currentUser.locale;
        }

        this.$CpEvent.$emit("user-loaded");
      });
    }
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,700,800|Lato:300,300i,400,700,900|Roboto+Condensed:300,400,700&display=swap");

.v-application--wrap {
  &::before {
    background-image: url("https://assets.swiftbunny.com/public-assets/app-ingage/header_bg.png"),
      linear-gradient(90deg, #007dba 0%, #009a44 100%);
  }

  .cp-hide-background-img &::before {
    display: none;
  }
}

.app-container {
  position: relative;
  padding-bottom: 180px !important;
}

body main .container.app-container.container--fluid {
  height: 100%;
  padding-bottom: 0 !important;
}

.cp-banner {
  z-index: 51 !important;
}

.app-footer {
  width: calc(100% - 24px);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
