<template>
  <div class="survey-question">
    <v-row align="center">
      <v-col :lg="bodyCols" cols="12">
        <div class="survey-question__text">
          <h6>
            {{ question.body }}
          </h6>
        </div>
      </v-col>

      <component
        :is="component"
        :question="question"
        :response="response"
        v-bind="componentProps"
      />
    </v-row>

    <Question
      v-for="child in question.children"
      v-show="passesCriteria(child)"
      :key="child.id"
      :question="child"
    />
  </div>
</template>

<script>
import * as QuestionTypes from "@/components/IngageSurveys/QuestionTypes";

export default {
  name: "Question",
  components: { ...QuestionTypes },
  props: {
    question: { type: Object, required: true }
  },

  computed: {
    response() {
      return this.$store.getters.response(this.question);
    },

    component() {
      if (
        ["agreement", "likelihood", "importance"].includes(
          this.question.kind.toLowerCase()
        )
      ) {
        return "SingleScale";
      }

      return this.question.kind.replace(/[ /]/, "");
    },

    bodyCols() {
      switch (this.component) {
        case "MultipleSelect":
        case "MultipleChoice":
          return 12;
        case "YesNo":
          return 8;
        default:
          return 4;
      }
    },

    componentProps() {
      let props = {};

      switch (this.component) {
        case "TextEntry":
          if (this.question.autocomplete_endpoint) {
            props[
              "autocomplete-data-endpoint"
            ] = this.question.autocomplete_endpoint;
          }
      }

      return props;
    }
  },

  methods: {
    passesCriteria(child) {
      return this.$store.getters.passesCriteria(
        this.question.id,
        child.parent_criteria
      );
    }
  }
};
</script>
