import Vue from "vue";
import vuetify from "carrot-patch-v2/src/plugins/vuetify";
import App from "./App.vue";
import { CarrotPatchV2 } from "carrot-patch-v2/src/install";
import "carrot-patch-v2/src/styles/index.scss";
import "./styles/survey-bunny-ui.scss";
import router from "./router";
import { Components } from "./install";
import store from "./store";
import VuePluralize from "vue-pluralize";
import VueStringFilter from "vue-string-filter";
import "./styles/global.scss";
import "./styles/ingage-ui.scss";

Vue.config.productionTip = false;

Vue.use(Components);
Vue.use(CarrotPatchV2);
Vue.use(VuePluralize);
Vue.use(VueStringFilter);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
