<template>
  <div class="survey-section">
    <v-row no-gutters justify="center" align="center">
      <v-col cols="auto">
        <h5 class="survey-section__title" :class="color">
          <cp-icon v-if="icon" :icon="icon" color="white" />
          {{ section.name }}
        </h5>
      </v-col>
    </v-row>

    <p class="survey-section__instructions">{{ section.instructions }}</p>

    <Question
      v-for="question in section.questions"
      :key="question.id"
      :question="question"
    />
  </div>
</template>

<script>
import Question from "@/components/IngageSurveys/Question.vue";
const swift = ["satisfaction", "workplace", "integrity", "future", "teamwork"];

export default {
  components: { Question },

  props: {
    section: { type: Object, required: true }
  },

  computed: {
    key() {
      if (!this.section.factor_name) {
        return null;
      }

      return this.section.factor_name.toLowerCase();
    },

    icon() {
      return swift.includes(this.key) ? `swift-${this.key}` : null;
    },

    color() {
      return `swift-${this.key}-color`;
    }
  }
};
</script>
