<template>
  <v-col lg="8" cols="12">
    <div class="survey-question__input">
      <v-row align="center" no-gutters>
        <v-col md="6" cols="12">
          <!-- TODO: align things better for this singleton -->
        </v-col>

        <v-col md="6" cols="12">
          <div class="survey-question__inputs__bar">
            <Scale :type="type" :question="question" :response="response" />
          </div>
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>

<script>
export default {
  props: {
    question: {type: Object, required: true},
    response: {type: Object, required: true},
  },

  computed: {
    type() {
      return this.question.kind.replace(/[ /]/, '').toLowerCase();
    },
  },
};
</script>
