<template>
  <v-row align="center" class="question-wrapper">
    <v-col cols="12" lg="4">
      {{ question.body || "Text of the question." }}
    </v-col>
    <v-col class="question-wrapper__q-column" cols="12" lg="8">
      <component
        :is="`q-${qCompTypeMap[question.type.slug]}`"
        :question="question"
        :response="response"
        @response-updated="updateResponse"
      />
    </v-col>
  </v-row>
</template>

<script>
import QuestionsShared from "./QuestionsShared.vue";
import { mapState } from "vuex";

export default {
  props: {
    question: Object,
    response: Object
  },

  computed: {
    ...mapState({
      survey: state => state.surveyRenderService.instance.survey,
      qCompTypeMap: state => state.question.question_type_component_map
    })
  },

  methods: {
    updateResponse($event) {
      this.$store.dispatch("surveyRenderService/postResponse", $event);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";

.question-wrapper {
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 1px solid #cccccc;
  text-align: center;

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    text-align: left;
  }

  .question-wrapper__q-column {
    text-align: center;

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      text-align: right;
    }
  }
}
</style>
