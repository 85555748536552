import { InquireSurvey, IngageSurvey } from "../views";
import canViewCPA from "./middleware/canViewCPA";

const routes = [
  {
    path: "/surveys/:id/preview",
    name: "survey_preview",
    component: InquireSurvey
  },
  {
    path: "/feedback/:slug/:id",
    name: "feedback",
    component: InquireSurvey
  },
  {
    path: "/feedback/weblink/:campaign_id/:survey_id",
    name: "feedback_weblink",
    component: InquireSurvey
  },
  {
    // serviceSlug - intouch or ingage
    path: "/:serviceSlug/:slug",
    name: "survey",
    component: IngageSurvey,
    meta: {
      fullWidth: true,
      hideBgImg: true
    }
  },
  {
    path: "/profile",
    name: "survey.profile",
    component: IngageSurvey,
    beforeEnter: canViewCPA,
    meta: {
      fullWidth: true,
      hideBgImg: true
    }
  }
];

export default routes.map(route => {
  if (!route.props) {
    route.props = route => ({
      ...route.query,
      ...route.params
    });
  }

  return route;
});
