<template>
  <div v-if="available_locales.length > 1">
    <v-menu transition="slide-x-transition" bottom right offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-icon large color="primary" class="d-sm-none" v-bind="attrs" v-on="on"
          >mdi-web</v-icon
        >
        <v-btn
          text
          color="primary"
          v-bind="attrs"
          class="d-none d-sm-block font-weight-bold"
          v-on="on"
        >
          <v-icon color="primary" class="mr-sm-1">mdi-web</v-icon>
          {{
            available_locales.find(
              x => x.value == translations.language_preference
            ).text
          }}
        </v-btn>
      </template>

      <v-list dense class="pa-0">
        <v-list-item
          v-for="(locale, index) in available_locales"
          :key="index"
          dense
          class="pa-0"
          style="min-height: 0;"
        >
          <v-btn
            :disabled="locale.value == translations.language_preference"
            text
            tile
            block
            @click="translations.language_preference = locale.value"
          >
            {{ locale.label }}
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog v-model="languageConfirmation" width="500">
      <v-card>
        <v-card-title class="headline">
          {{ $store.state.translations.terms.save_language_title }}
        </v-card-title>

        <v-card-text>
          {{ $store.state.translations.terms.save_language_body }}
        </v-card-text>

        <v-container>
          <v-row dense class="d-flex flex-column flex-sm-row-reverse">
            <v-col cols="12" sm="6" align="center">
              <v-btn
                color="primary"
                :disabled="savingLanguage"
                text
                @click="languageConfirmation = false"
              >
                {{ $store.state.translations.terms.save_language_no }}
              </v-btn>
              <v-btn
                color="primary"
                :loading="savingLanguage"
                class="ml-2"
                @click="saveLanguagePreference()"
              >
                {{ $store.state.translations.terms.save_language_yes }}
              </v-btn>
              <v-divider class="mt-4 hidden-sm-and-up" />
            </v-col>
            <v-col cols="12" sm="6" align="center">
              <v-btn
                color="primary"
                text
                :disabled="savingLanguage"
                @click="revertLanguage()"
              >
                Undo/Deshacer
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="showStatusUpdate"
      :timeout="snackbarPersistTime"
      top
      elevation="12"
      color="primary"
    >
      <h5 class="font-weight-bold">{{ statusMessage }}</h5>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="showStatusUpdate = false"
        >
          {{ $store.state.translations.terms.close }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      languageConfirmation: false,
      savingLanguage: false,
      showStatusUpdate: false,
      statusMessage: "",
      snackbarPersistTime: 4000,
      triggerSaveLanguageDialog: true,
    };
  },

  computed: {
    ...mapState({
      available_locales: state => state.translations.available_locales,
      translations: state => state.translations,
      current_user: state => state.auth.currentUser,
      survey: state => state.survey.survey,
    }),

    // language_preference: {
    //   set(language_preference) {
    //     this.languageConfirmation = true;
    //     this.$store.dispatch("fetchTerms", language_preference);
    //     this.$store.dispatch("fetchSurvey", {
    //       slug: this.survey_slug,
    //       locale: language_preference
    //     });
    //   },
    //   get() {
    //     return this.language_preference;
    //   }
    // },

    survey_slug() {
      return this.$route.params.slug;
    },
  },

  watch: {
    "translations.language_preference"() {
      this.languageConfirmation = true;
      this.$store.dispatch("fetchTerms", this.translations.language_preference);
      this.$store.dispatch("fetchSurvey", {
        slug: this.survey_slug,
        locale: this.translations.language_preference,
      });
    },
  },

  methods: {
    saveLanguagePreference() {
      let self = this;
      self.savingLanguage = true;

      this.$store
        .dispatch("updateLanguagePreference", {
          survey_slug: this.survey_slug,
          locale: this.translations.language_preference,
        })
        .then(() => {
          this.$store
            .dispatch("fetchTerms", this.translations.language_preference)
            .then(() => {
              self.languageConfirmation = false;
              self.savingLanguage = false;
              self.statusMessage =
                self.$store.state.translations.terms.save_language_confirmation;
              self.showStatusUpdate = true;

              if (self.$route.query.locale != null) {
                let path = this.$route.path;
                self.$router.push({
                  path: path,
                  query: { section: self.$route.query.section },
                });
              }
            });
        });
    },

    revertLanguage() {
      this.triggerSaveLanguageDialog = false;
      let previousLanguage =
        this.translations.language_preference == "en" ? "es" : "en";

      this.translations.language_preference = previousLanguage;

      this.$store.dispatch("fetchTerms").then(() => {
        this.languageConfirmation = false;
        this.statusMessage = this.$store.state.translations.terms.language_reverted;
        this.showStatusUpdate = true;
        this.triggerSaveLanguageDialog = true;
      });
      this.$store.dispatch("fetchSurvey", {
        slug: this.survey_slug,
        locale: previousLanguage,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.language-picker {
  width: 160px;
}

.v-card__title {
  background-color: $blue--main;
  color: $white;
  margin-bottom: 1rem;
}
</style>
